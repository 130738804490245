<template>
  <div>
    <v-row>
      <v-col
        cols="10"
        offset="2"
      >
        <div class="text-center mx-auto d-all justify-center position-relative">
          <div
            data-tf-widget="Br2Yyijr"
            style="width:80%; height:600px;"
          ></div>
        </div>
      </v-col>
    </v-row>
    <div class="text-center mt-3">
      Already have an account?
      <a href="/login">Login</a>
    </div>
  </div>
</template>

<script>
import {mdiAlert} from '@mdi/js'

export default {
  props: {
    leagueCode: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
  mounted() {
    if (this.$store.state.user) {
      this.$router.push({path: 'dashboard'})
    } else {
      const script = document.createElement('script')
      script.src = 'https://embed.typeform.com/next/embed.js'
      document.body.appendChild(script)
    }
  },
}
</script>

<style lang="scss">
</style>
